import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'gatsby';
import { useMediaQuery } from 'beautiful-react-hooks';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import { store } from '../store/store';
import Title from './Title';
import Chevron from '../../static/images/chevron.svg';
import Idea from '../../static/images/idea.png';
import Image from './Image';

const Wrapper = styled.aside`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    max-width: 60rem;
    overflow: hidden;
  }

  /* &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://assets.website-files.com/5bfd1275cc56e15ce750b18e/5c289afb9a157506f8893ae1_99.%20Roman.jpg');
    background-size: cover;
    background-position: center;
    z-index: -1;
  } */

  @media (${({ theme }) => theme.respondTo.desktop}) {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 39vw;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  background: white;
  border-radius: 5px 5px 0 0;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: ${({ isOpen }) => (isOpen ? 'auto' : 'pointer')};
  margin: -4rem 1rem 1rem;
  padding: 2.4rem;
  box-shadow: 0 0 50px 0px rgba(142, 142, 142, 0.16);
  z-index: 1;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    margin-bottom: 20rem;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 0 3rem;
    padding: 3rem 2.4rem;
    transform: ${({ isOpen }) =>
      isOpen ? 'translateY(0)' : 'translateY(calc(100% - 8rem))'};
  }
`;

const StyledTitle = styled(Title)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    font-size: 2.6rem;
  }

  svg {
    display: none;
    width: 2rem;
    height: 2rem;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

    @media (${({ theme }) => theme.respondTo.desktop}) {
      display: block;
    }
  }
`;

const List = styled.ul`
  position: relative;
  margin: 3rem 0;
  padding-bottom: 3rem;

  &:after {
    content: '';
    position: absolute;
    left: -3rem;
    right: -3rem;
    bottom: 0;
    height: 1px;
    background-color: #e8e8e8;

    @media (${({ theme }) => theme.respondTo.mobile}) {
      left: -2.4rem;
      right: -2.4rem;
    }
  }
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 3rem;

  &:not(:last-child) {
    margin: 0 0 2rem;
  }

  span {
    position: absolute;
    left: 0;
    top: 1px;
    font-family: ${({ theme }) => theme.fontFamilyHeading};
  }
`;

const Img = styled.img`
  display: none;
  padding-left: ${({ small }) => (small ? '0' : '3rem')};
  height: ${({ small }) => (small ? '10rem' : '17.5rem;')};

  @media (${({ theme }) => theme.respondTo.mobile}) {
    display: block;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: block;
    bottom: 8rem;
    position: fixed;
  }
`;

const Footnote = styled.div`
  display: block;
  margin: 2rem auto 3rem;
  font-family: ${({ theme }) => theme.fontFamilyHeading};

  @media (${({ theme }) => theme.respondTo.mobile}) {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    left: 0;
    width: 100%;
    bottom: 2rem;
    margin: 0 0 1.5rem;

    > p {
      width: 100%;
      text-transform: uppercase;
      font-size: 1.5rem;
      text-align: center;
      margin: 0.75rem 0 0;
    }
  }
`;

const CtaParagraph = styled.p`
  a {
    text-decoration: underline;
  }
`;

const Sidebar = () => {
  const { state, dispatch } = useContext(store);
  const [isOpen, setIsOpen] = useState(state.howItWorksIsOpen);
  const scrollRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 47.999rem)');
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  useEffect(() => {
    setIsOpen(state.howItWorksIsOpen);
  }, [state.howItWorksIsOpen]);

  useEffect(() => {
    if (state.shouldScrollIntoView) {
      scrollIntoView(scrollRef.current, {
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [state.shouldScrollIntoView]);

  return (
    <Wrapper ref={scrollRef}>
      <Image />
      {isDesktop && <Img src={Idea} />}
      <Content
        isOpen={isOpen}
        onClick={() => !isOpen && dispatch({ type: 'TOGGLE_HOW_IT_WORKS' })}
      >
        <StyledTitle
          isOpen={isOpen}
          onClick={() => isOpen && dispatch({ type: 'TOGGLE_HOW_IT_WORKS' })}
        >
          Hoe het werkt <SVG src={Chevron} />
        </StyledTitle>
        <List>
          <ListItem>
            <span>1.</span>Controleer of je gewenste naam nog beschikbaar is en
            klik op 'Webshop aanvragen'.
          </ListItem>
          <ListItem>
            <span>2.</span>Na registratie ontvang je van ons een document. Vul
            hier je teksten & producten in en upload je foto's. <br /> Geen
            technische kennis vereist.
          </ListItem>
          <ListItem>
            <span>3.</span>That's all. Verspreid het nieuws aan de wereld! 🎉
          </ListItem>
        </List>
        <p>
          Zodra de klant een bestelling heeft geplaatst op je webshop, ontvang
          je hiervan een mailtje.
        </p>
        <p>
          De verdere afhandeling v.w.b bezorging/afhaling & betaling stem je
          vervolgens zelf af met de klant. Denk hierbij bijv. aan een
          pinbetaling aan de deur of het versturen van een Tikkie.
        </p>
        <CtaParagraph>
          <b>Vragen?</b> Bekijk de <Link to="/faq/">FAQ</Link>, of{' '}
          <a href="mailto:hi@lokaly.shop">mail ons!</a>
        </CtaParagraph>
      </Content>
      {isMobile && (
        <Footnote>
          <Img small src={Idea} />
          <p>From AMS ❤️</p>
        </Footnote>
      )}
    </Wrapper>
  );
};

export default Sidebar;

import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import AutosizeInput from 'react-input-autosize';
import axios from 'axios';
import SVG from 'react-inlinesvg';

import { store } from '../store/store';
import Field from './Field';
import Button from './Button';
import Check from '../../static/images/check.svg';
import Error from '../../static/images/error.svg';
import Man from '../../static/images/man.png';
import DomainInput from './DomainInput';

const Wrapper = styled.div`
  position: relative;
  margin: 5rem 0 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 7.5rem 0 0;
  }
`;

const Img = styled.img`
  width: 10rem;
  margin: 3rem 0 0 2rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 14rem;
    margin: 5rem 0 0 3rem;
  }
`;

const StyledButton = styled(Button)`
  margin: 2rem 0 0;
`;

const Form = () => {
  const { dispatch } = useContext(store);
  const [subdomains, setSubdomains] = useState('');
  const [value, setValue] = useState('');
  const [ref, setRef] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get('https://lokaly.shop/app/clients.json');
      setSubdomains(Object.keys(data));
    })();
  }, []);

  useEffect(() => {
    const hasErrors =
      value.match(/[^a-z\d\-]/gi) ||
      value.length > 20 ||
      subdomains.includes(value);
    hasErrors ? setError(true) : setError(false);
  }, [value]);

  const handleSubmit = e => {
    e.preventDefault();
    if (value.length === 0) {
      ref.focus();
    } else {
      dispatch({ type: 'TOGGLE_MODAL_IS_OPEN' });
      dispatch({ type: 'SET_SUBDOMAIN_VALUE', data: value });
    }
  };

  return (
    <Wrapper>
      <form>
        <DomainInput>
          <AutosizeInput
            inputRef={el => setRef(el)}
            name="domain"
            value={value}
            placeholder="jouw-zaak"
            onChange={event => setValue(event.target.value.toLowerCase())}
          />
          <span>.lokaly.shop</span>
          {value && !error && <SVG src={Check} />}
          {value && error && <SVG src={Error} />}
        </DomainInput>
        <StyledButton onClick={e => handleSubmit(e)} disabled={value && error}>
          Webshop aanvragen
        </StyledButton>
      </form>
      <Img src={Man} alt="" />
    </Wrapper>
  );
};

export default Form;

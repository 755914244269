import React, { useContext } from 'react';
import styled from 'styled-components';
import TextLoop from 'react-text-loop';

import { store } from '../store/store';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Title from '../components/Title';
import Form from '../components/Form';

const StlyedTitle = styled(Title)`
  margin: 0 0 1.5rem;
  font-size: 4rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: 6rem;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 7.2rem;
  }

  @media (${({ theme }) => theme.respondTo.iphone5}) {
    font-size: 3.35rem;
  }
`;

const Wrapper = styled.div`
  margin: 10rem 0;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: 15rem 0 10rem;
    width: 65%;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    align-self: center;
    margin: 7.5rem 0 0;
    width: 100%;
  }
`;

const Content = styled.div`
  max-width: 60rem;
`;

const IndexPage = () => {
  const { state, dispatch } = useContext(store);

  const seo = {
    title: 'lokaly.shop — Een webshop platform voor lokale helden.',
    description:
      'Bied je producten online aan & kom weer in contact met je klanten. Gratis.',
  };

  const titles = [
    'lokale held!',
    'kapper!',
    'brouwerij!',
    'barista!',
    'bloemist!',
    'bakkerij!',
    'juicer!',
    'cafe!',
    'ondernemer!',
    'specialist!',
    'jij daar!',
  ];

  return (
    <Layout seo={seo}>
      <Wrapper>
        <Container>
          <Content>
            <StlyedTitle as="h1" primaryColor>
              Hey,{' '}
              <TextLoop
                delay={500}
                springConfig={{ stiffness: 100, damping: 10 }}
                interval={1500}
                children={titles}
              />
            </StlyedTitle>
            <Title big>
              Bied je producten online aan & kom weer in contact met je klanten.
              Gratis.
            </Title>
            <Form />
          </Content>
        </Container>
      </Wrapper>
      <Sidebar />
    </Layout>
  );
};

export default IndexPage;

import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const StyledImg = styled(Img)`
  @media (${({ theme }) => theme.respondTo.desktop}) {
    @keyframes float {
      0% {
        transform: rotate(0px) scale(1);
      }
      50% {
        transform: rotate(10deg) scale(1.5);
      }
      100% {
        transform: rotate(0px) scale(1);
      }
    }
    animation: float 80s ease-in-out infinite;
  }
`;

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledImg
      imgStyle={{ objectPosition: 'left center' }}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: -1,
      }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  );
};

export default Image;

import styled from 'styled-components';

const DomainInput = styled.div`
  position: relative;
  width: 100%;
  width: 100%;
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamilyHeading};

  span {
    font-size: 2.4rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      font-size: 3.2rem;
    }

    @media (${({ theme }) => theme.respondTo.iphone5}) {
      font-size: 2rem;
    }
  }

  input {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    border: none;
    color: inherit;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    max-width: 18rem;
    padding: 0;
    text-overflow: ellipsis;
    font-size: 2.4rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      font-size: 3.2rem;
      max-width: 40rem;
    }

    @media (${({ theme }) => theme.respondTo.iphone5}) {
      font-size: 2rem;
      max-width: 14rem;
    }

    &::placeholder {
      color: #b8b8b8;
    }
  }

  svg {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    transform: translate(0.5rem, -1rem);
  }
`;

export default DomainInput;
